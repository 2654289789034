import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./Footer.css"
import footerLogo from "../../assets/logo/logo-te-small.svg"

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulBlogPost(
                sort: { fields: datePublished, order: DESC }
            ) {
                edges {
                    node {
                        slug
                        content {
                            childMarkdownRemark {
                                excerpt
                            }
                        }
                    }
                }
            }
        }
    `)

    const blogPostUrl = `/blog/${data.allContentfulBlogPost.edges[0].node.slug}`

    return (
        <footer className="Footer">
            <div className="Footer-top Footer-container">
                <div className="Footer-top-inner">
                    <div className="Footer-links">
                        <h3 className="Footer-title">Get in Touch</h3>
                        <ul>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/thinking-engineers"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="Footer-social-link"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 146 146"
                                        className="Footer-social-icon"
                                    >
                                        <path
                                            d="M130.72.81H15.37C7.44.81.95 7.3.95 15.23v115.35c0 7.93 6.49 14.42 14.42 14.42h115.35c7.93 0 14.42-6.49 14.42-14.42V15.23c0-7.93-6.49-14.42-14.42-14.42zM44.21 123.37H22.58V58.49h21.63v64.88zM33.39 46.23c-7.21 0-12.98-5.77-12.98-12.98s5.77-12.98 12.98-12.98 12.98 5.77 12.98 12.98-5.77 12.98-12.98 12.98zm90.12 77.14h-21.63V85.16c0-5.77-5.05-10.81-10.81-10.81S80.26 79.4 80.26 85.16v38.21H58.63V58.49h21.63v8.65c3.6-5.77 11.54-10.09 18.02-10.09 13.7 0 25.23 11.54 25.23 25.23v41.09z"
                                            fill="#6a81a3"
                                        />
                                    </svg>
                                    <span className="Footer-social-text">
                                        LinkedIn
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/thinkengineers/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="Footer-social-link"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 122 122"
                                        className="Footer-social-icon"
                                    >
                                        <path
                                            d="M109.12.81H12.97C6.36.81.95 6.22.95 12.83v96.15c0 6.61 5.41 12.02 12.02 12.02h96.15c6.61 0 12.02-5.41 12.02-12.02V12.83c0-6.61-5.41-12.02-12.02-12.02zm-6.01 12.02v18.03H91.09c-3.61 0-6.01 2.4-6.01 6.01v12.02h18.03v18.03H85.08v42.07H67.06V66.91H55.04V48.89h12.02V33.86c0-11.42 9.62-21.03 21.03-21.03h15.02z"
                                            fill="#6a81a3"
                                        />
                                    </svg>
                                    <span className="Footer-social-text">
                                        Facebook
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/think_engineers"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="Footer-social-link"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 171 170"
                                        className="Footer-social-icon"
                                    >
                                        <path
                                            d="M152.82.81H18.27C9.02.81 1.45 8.38 1.45 17.63v134.55c0 9.25 7.57 16.82 16.82 16.82h134.55c9.25 0 16.82-7.57 16.82-16.82V17.63c0-9.25-7.57-16.82-16.82-16.82zM133.48 62.2c-.84 38.68-25.23 65.59-62.23 67.28-15.14.84-26.07-4.2-36.16-10.09 10.93 1.68 25.23-2.52 32.8-9.25-10.93-.84-17.66-6.73-21.02-15.98 3.36.84 6.73 0 9.25 0-10.09-3.36-16.82-9.25-17.66-22.71 2.52 1.68 5.89 2.52 9.25 2.52-7.57-4.2-12.61-20.18-6.73-30.27 10.93 11.77 24.39 21.86 46.25 23.55-5.89-23.55 26.07-36.16 38.68-20.18 5.89-.84 10.09-3.36 14.3-5.05-1.68 5.89-5.05 9.25-9.25 12.61 4.2-.84 8.41-1.68 11.77-3.36-.84 4.2-5.04 7.56-9.25 10.93z"
                                            fill="#6a81a3"
                                        />
                                    </svg>
                                    <span className="Footer-social-text">
                                        Twitter
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <Link className="Footer-message" to={blogPostUrl}>
                        <h4 className="Footer-message-title">
                            Latest from the blog
                        </h4>
                        <div
                            className="Footer-message-text"
                            dangerouslySetInnerHTML={{
                                __html:
                                    data.allContentfulBlogPost.edges[0].node
                                        .content.childMarkdownRemark.excerpt,
                            }}
                        />
                    </Link>
                </div>
            </div>
            <div className="Footer-bottom Footer-container">
                <div className="Footer-bottom-inner">
                    <span className="Footer-copyright">
                        Copyright {new Date().getFullYear()}.{" "}
                    </span>
                    <img
                        src={footerLogo}
                        alt="Thinking engineers logo"
                        className="Footer-logo"
                    />{" "}
                    <span className="Footer-copyright">
                        All rights reserved
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default Footer
