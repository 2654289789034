import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Navigation from "./Navigation/Navigation"
import Logo from "./Logo/Logo"
import "./Header.css"

const Header = () => {
    useEffect(() => {
        const hero = document.querySelector(".hero")
        if (hero === null) return

        const header = document.querySelector(".header")
        const options = {
            root: null,
            rootMargin: "-450px 0px 0px 0px",
            threshold: 0,
        }

        const handler = entry => {
            if (!entry[0].isIntersecting) header.classList.add("sticky")
            else header.classList.remove("sticky")
        }

        if ("IntersectionObserver" in window) {
            const observer = new IntersectionObserver(handler, options)
            observer.observe(hero)
            return () => observer.unobserve(hero)
        }
    }, [])

    return (
        <header className="header">
            <div className="header-inner">
                <Logo />
                <Navigation />
            </div>
        </header>
    )
}

Header.propTypes = { siteTitle: PropTypes.string }
Header.defaultProps = { siteTitle: `` }

export default Header
