import React from "react"
import hamburger from "../../../assets/icon/icon-hamburger.svg"
import close from "../../../assets/icon/icon-close.svg"
import { Link } from "gatsby"
import "./Navigation.css"
import data from "../../../../content/navItems.json"

const Navigation = () => {
    const [activeItem, setActiveItem] = React.useState("")
    const [hamburgerClicked, setHamburgerClicked] = React.useState(false)

    React.useEffect(() => scrollTo())

    function scrollTo() {
        const links = document.querySelectorAll(".navigation-item[href^='#']")
        if (links) {
            links.forEach(link => link.addEventListener("click", scrollAnchors))
        }
    }

    function scrollAnchors(e, respond = null) {
        const distanceToTop = el => {
            if (window.pageYOffset === 0) {
                if (window.innerWidth < 768) {
                    return Math.floor(el.getBoundingClientRect().top - 80)
                } else {
                    return Math.floor(el.getBoundingClientRect().top - 48)
                }
            } else {
                return Math.floor(el.getBoundingClientRect().top)
            }
        }
        e.preventDefault()

        var targetID = respond
            ? respond.getAttribute("href")
            : this.getAttribute("href")
        const targetAnchor = document.querySelector(targetID)
        if (!targetAnchor) return

        const originalTop = distanceToTop(targetAnchor) - 80
        window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" })

        const checkIfDone = setInterval(function() {
            const atBottom =
                window.innerHeight + window.pageYOffset >=
                document.body.offsetHeight - 2

            if (distanceToTop(targetAnchor) === 0 || atBottom) {
                targetAnchor.tabIndex = "-1"
                targetAnchor.focus()
                window.history.pushState("", "", targetID)
                clearInterval(checkIfDone)
            }
        }, 100)
    }

    let navClass = ""

    if (typeof window !== "undefined") {
        navClass = window.innerWidth >= 768 ? "navigation-desktop" : ""
    }

    const handleItemClick = e => {
        setActiveItem(e.target.text)
        setHamburgerClicked(!hamburgerClicked)
    }

    const items = data.navItems.map(item => {
        const navigationItemActive =
            item.name === activeItem ? "navigation-item-active" : ""

        if (item.target.includes("#")) {
            return (
                <a
                    className={`navigation-item ${navigationItemActive}`}
                    key={item.name}
                    onClick={handleItemClick}
                    href={item.target}
                >
                    {item.name}
                </a>
            )
        } else if (item.target) {
            return (
                <Link
                    className={`navigation-item ${navigationItemActive}`}
                    key={item.name}
                    onClick={handleItemClick}
                    to={`/${item.target}`}
                >
                    {item.name}
                </Link>
            )
        }

        return false
    })

    return (
        <div className={`navigation ${navClass}`}>
            <button
                type="button"
                onClick={() => setHamburgerClicked(!hamburgerClicked)}
                onKeyDown={() => setHamburgerClicked(!hamburgerClicked)}
                className="hamburger"
            >
                <img
                    className="hamburger-icon"
                    src={hamburgerClicked ? close : hamburger}
                    alt="Hamburger icon"
                />
            </button>
            <div
                className={`navigation-menu ${
                    hamburgerClicked ? "navigation-expanded" : ""
                }`}
            >
                {items}
            </div>
        </div>
    )
}

export default Navigation
