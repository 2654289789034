/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { Location } from "@reach/router"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
// import BlogFooter from "../components/BlogFooter/BlogFooter"

const Layout = ({ location, children }) => {
    let hasHeader = location.pathname === "/" ? "Main--has-header" : ""

    return (
        <>
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css?family=Titillium+Web:300,300i,400,700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <Location>
                {({ location }) => {
                    if (location.pathname === "/") return <Header />
                }}
            </Location>
            <main className={`Main ${hasHeader}`}>{children}</main>
            <Footer />
            {/* <Location>
                {({ location }) => {
                    if (location.pathname === "/") return <Footer />
                    if (location.href.indexOf("/blog") > -1)
                        return <BlogFooter />
                }}
            </Location> */}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
