import React from "react"
import { Link } from "gatsby"
import logo from "../../../assets/vector/vector-box-and-logo.svg"
import "./Logo.css"

const Logo = () => {
    return (
        <div className="logo">
            <div className="logo-block">
                <Link
                    className="logo-link"
                    to="/"
                    title="Thinking Engineers Logo"
                >
                    <img
                        className="logo-image"
                        src={logo}
                        alt="Thinking Engineers Logo"
                    />
                </Link>
            </div>
        </div>
    )
}

export default Logo
